<template>
	<div class="card-body">
		<b-row>
			<b-col style="overflow: auto">
				<b-list-group>
					<template v-for="item in mainPermissions">
						<switch-list
							:key="item.id"
							:checked="permission[item.id]"
							:tag="translate(item.code_name)"
							:item-id="item.id"
							:children="item.children"
							:parent-status="permission[item.id]"
							variant="success"
							pill
							class="pr-3"
							size="sm"
							@changeVal="changeVal"
							@parent="parent(item.id)"
							@clearError="clearError('permissions')" />
					</template>
					<template v-if="hasError('permissions')">
						<span
							v-for="error in errors.errors['permissions']"
							:key="error"
							class="invalid-feedback d-block animated fadeIn"
							v-text="error" />
					</template>
				</b-list-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="col-12 col-md-12 mt-3">
				<form
					@submit.prevent="save"
					@keydown="clearError($event.target.name);">
					<div class="form-group">
						<label
							for="login_password"
							class="label">
							{{ translate('password') }}
							<span class="text-danger">*</span>
						</label>
						<input-password
							id="password"
							v-model="password"
							:input-class="`${hasError('password') ? 'is-invalid' : ''} form-control`"
							toggle-class="form-control"
							:placeholder="translate('password')"
							name="password" />
						<template v-if="hasError('password')">
							<span
								v-for="error in errors.errors['password']"
								:key="error"
								class="invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</div>
					<div>
						<button
							:disabled="Object.keys(errors.errors).length > 0"
							class="btn btn-primary float-right">
							{{ translate('save') }}
						</button>
					</div>
				</form>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import InputPassword from '@/components/InputPassword';
import SwitchList from '@/components/Switch/SwitchList';
import { Profile, Permissions, Validations } from '@/translations';
import Permission from '@/util/Permission';
import store from './store';

Vue.component('switch-list', SwitchList);
const currentUserPermission = new Permission();

export default {
	name: 'Permissions',
	components: { InputPassword, SwitchList },
	store,
	messages: [Profile, Validations, Permissions],
	data() {
		return {
			perms: new Permission(),
			currentUserPermission,
			alert: new this.$Alert(),
			corporateId: '',
			password: '',
			userPermissions: [],
		};
	},
	computed: {
		...mapState([
			'permission',
			'newPermissions',
		]),
		loading() {
			return !!this.perms.data.loading;
		},
		errors() {
			return this.perms.data.errors;
		},
		pagination() {
			return this.perms.data.pagination;
		},
		mainPermissions() {
			try {
				return this.currentUserPermission.data.response.data.response;
			} catch (error) {
				return [];
			}
		},
		data() {
			try {
				const { data } = this.perms.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		if (typeof this.$route.params.hasAccessGroup === 'undefined' || this.$route.params.hasAccessGroup) {
			this.$router.push({ name: 'Corporates' });
		}
		let { username } = this.$route.params;
		if (typeof username === 'undefined') {
			username = '';
		}
		this.$parent.titleParam = { corporate: username };
		this.corporateId = this.$route.params.corporateId;
		this.getData();
	},
	methods: {
		...mapActions(['setNewPermissions', 'setPermission', 'change']),
		async getData() {
			await this.currentUserPermission.getPermissions();
			this.perms.getPermissions(this.corporateId).then((response) => {
				this.userPermissions = response.response;
				const userPermissionsIds = [];
				this.userPermissions.forEach((item) => {
					userPermissionsIds.push(item.id);
				});
				this.transformPermissions(userPermissionsIds, this.mainPermissions);
				this.$forceUpdate();
			});
		},
		changeVal(value) {
			const payload = { value, id: this.item_id };
			this.change(payload);
		},
		save() {
			const payload = {
				corporateId: this.corporateId,
				permissions: this.newPermissions,
				password: this.password,
			};

			this.perms.updatePermissions(payload).then(() => {
				this.password = '';
				this.alert.toast('success', this.translate('persmissions_assigned_successfully'));
				this.$router.push({ name: 'Corporates' });
			}).catch(() => {});
		},
		hasError(field) {
			if (typeof this.errors.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors.errors[field];
				this.errors.errors = { ...this.errors.errors };
			}
		},
		transformPermissions(userPermissionsIds, main) {
			main.forEach((item) => {
				const payload = { index: item.id, value: false };
				if (userPermissionsIds.includes(item.id)) {
					payload.value = true;
					this.setNewPermissions(item.id);
				}

				this.setPermission(payload);
				if (typeof item.children !== 'undefined') {
					this.transformPermissions(userPermissionsIds, item.children);
				}
			});
		},
		parent(value) {
			this.clearError('permissions');
			const checked = this.permission[value];
			const payload = { value: true, id: value };

			if (!checked) {
				this.change(payload);
				this.$parent.$emit('parent');
			}
		},
	},
};
</script>
