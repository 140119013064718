import { UPDATE_PERMISSION, GET_PERMISSIONS } from '@/config/endpoint';
import Req from './AxiosRequest';

class Permissions {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
	}

	clear() {
		this.data.clear();
	}

	getPermissions(id) {
		const { method, endpoint } = GET_PERMISSIONS;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => Promise.reject(error));
	}

	updatePermissions(payload) {
		const { corporateId, permissions, password } = payload;
		const { method, endpoint } = UPDATE_PERMISSION;
		return this.data[method](endpoint(corporateId), { permissions, password }).then((response) => response).catch((error) => Promise.reject(error));
	}
}

export default Permissions;
