import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const permissions = () => new Vuex.Store({
	state: {
		permission: [],
		newPermissions: [],
	},
	getters: {},
	mutations: {
		handleChange(state, payload) {
			const { id, value } = payload;
			Vue.set(state.permission, id, value);

			if (value) {
				state.newPermissions.push(id);
			} else if (!value) {
				state.newPermissions.splice(
					state.newPermissions.indexOf(id),
					1,
				);
			}
		},
		setPermission(state, payload) {
			const { index, value } = payload;
			Vue.set(state.permission, index, value);
		},
		setNewPermissions(state, payload) {
			state.newPermissions.push(payload);
		},
		change(state, payload) {
			const { id, value } = payload;
			Vue.set(state.permission, id, value);
			if (value) {
				state.newPermissions.push(id);
			} else if (!value) {
				if (state.newPermissions.indexOf(id) > -1) {
					state.newPermissions.splice(state.newPermissions.indexOf(id), 1);
				}
			}
		},
	},
	actions: {
		handleChange({ commit }, payload) {
			commit('handleChange', payload);
		},
		setNewPermissions({ commit }, payload) {
			commit('setNewPermissions', payload);
		},
		setPermission({ commit }, payload) {
			commit('setPermission', payload);
		},
		change({ commit }, payload) {
			commit('change', payload);
		},
	},
});

export default permissions();
