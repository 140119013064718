<template>
	<ul class="p-0 m-0">
		<b-card-body class="py-2">
			<div
				:class="{bold: isFolder, pointer: isFolder}"
				class="col my-auto"
				@click="toggle">
				<p class="card-text text-nowrap">
					<i
						v-if="isFolder"
						:class="open ? 'fa fa-minus' : 'fa fa-plus'" />
					<span
						v-if="!isFolder"
						class="d-inline-block empty-space" />
					<i
						v-else
						class="fa fa-circle-thin" />
					<switch-toggle
						:checked="checked"
						variant="success"
						pill
						class="ml-3"
						@change="changeVal" />
					{{ tag }}
				</p>
			</div>
			<b-list-group
				v-show="open"
				v-if="isFolder">
				<template v-for="item in children">
					<switch-list
						:key="item.id"
						:checked="permission[item.id]"
						:parent-status="checked"
						:tag="translate(item.code_name)"
						:item-id="item.id"
						:children="item.children"
						variant="success"
						pill
						style="padding-right:50px"
						@change="changeVal"
						@parent="parent(item.id)" />
				</template>
			</b-list-group>
		</b-card-body>
	</ul>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import Switch from '@/components/Switch';
import { Profile, Permissions } from '@/translations';

export default {
	messages: [Profile, Permissions],
	components: {
		'switch-toggle': Switch,
	},
	model: {
		prop: 'checked',
		event: 'change',
	},
	props: {
		parentStatus: {
			type: Boolean,
			default: true,
		},
		value: {
			type: Boolean,
			default: true,
		},
		uncheckedValue: {
			type: Boolean,
			default: false,
		},
		checked: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'default',
		},
		tag: {
			type: String,
			default: '',
		},
		variant: {
			type: String,
			default: '',
		},
		pill: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		on: {
			type: String,
			default: null,
		},
		off: {
			type: String,
			default: null,
		},
		size: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		itemId: {
			type: Number,
			required: true,
		},
		children: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			open: false,
		};
	},
	computed: {
		classList() {
			return [];
		},
		...mapState(['permission']),
		isFolder() {
			return this.children.length > 0;
		},
	},
	watch: {
		checked(value) {
			if (value) {
				this.$parent.$emit('parent');
			}
		},
		parentStatus(value) {
			if (!value) {
				const payload = { value: false, id: this.itemId };
				this.change(payload);
			}
		},
	},
	methods: {
		...mapActions(['setNewPermissions', 'setPermission', 'change']),
		changeVal(value) {
			this.$emit('clearError');
			const payload = { value, id: this.itemId };
			this.change(payload);
		},
		parent(value) {
			this.$emit('clearError');
			const checked = this.permission[value];
			const payload = { value: true, id: value };

			if (!checked) {
				this.change(payload);
			}
		},
		toggle() {
			if (this.isFolder) {
				this.open = !this.open;
			}
		},
	},
};
</script>

<style>
	.empty-space {
		width: 25px;
	}
</style>
